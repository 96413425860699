import { watchEventSocket } from '../services/AffiliateService';

/**
 * watchEvents handles the events from the affiliate hub websocket
 * and updates the store.
 * 
 * @param {Store} the vuex store
 * @param {Router} the vue-router
 * @returns nothing
 */
export default function watchEvents(store, router) {
    if (!store) {
        return;
    }

    const ws = watchEventSocket();

    ws.onopen = function() {
        store.dispatch('s/on');
    };

    ws.onmessage = function(e) {
      console.log('Message:', e.data);
      const hevent = JSON.parse(e.data);
      if (hevent.action == 0) {
          return
      }

      store.dispatch('o/refresh').catch(() => router.replace({ name: "Login"}));
    };

    ws.onclose = function(e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      store.dispatch('s/off');
      setTimeout(function() {
        watchEvents(store);
      }, 1000);
    };

    ws.onerror = function(err) {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      ws.close();
    };
}
