export default {
  namespaced: true,
  state: () => ({
    connected: false
  }),
  mutations: {
    updateConnStat(state, connectedIn) {
        state.connected = connectedIn;
    }
  },
  actions: {
    on({commit}) {
        commit('updateConnStat', true);
    },
    off({commit}) {
        commit('updateConnStat', false);
    }
  },
  modules: {
  }
}
