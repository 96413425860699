<template>
  <v-container>
    <OutpostList/>
    <StatusBar/>
  </v-container>
  
</template>

<script>
import OutpostList from '../outposts/OutpostList.vue';
import StatusBar from '../statusbar/StatusBar.vue';
import watchEvents from './watch';

export default {
  components: {
    OutpostList,
    StatusBar,
  },
  name: 'Shebang',

  created: function() {
    if (!process.env.VUE_APP_DEBUG) {
      console.log = () => {};
    }

    console.log("VUE_APP_DEBUG:", process.env.VUE_APP_DEBUG);
    console.log("VUE_APP_BASE_URL:", process.env.VUE_APP_BASE_URL);
    this.$store.dispatch('o/refresh').catch(() => this.$router.replace("/login"));
    watchEvents(this.$store, this.$router);
  }
}
</script>
