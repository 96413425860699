<template>
  <v-bottom-navigation fixed>
    <v-btn @click="refresh()">Refresh</v-btn>
    <v-btn disabled>Status: {{ connectedVerbal }}</v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'StatusBar',
  computed: {
    connectedVerbal() {
      return (this.$store.state.s.connected) ? "Connected":"Not connected";
    }
  },
  methods: {
    refresh: function() {
      this.$store.dispatch('o/refresh').catch(() => this.$router.replace({ name: "Login"}));
    }
  },
}
</script>
