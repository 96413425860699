<template>
  <v-container>
    <v-row class="text-center">
            <v-col cols="12">
    <v-data-table
      :headers="headers"
      :items="outposts"
      :items-per-page="50"
      class="elevation-1"
      loading
      loading-text="Loading... Please wait"
    ></v-data-table>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OutpostList',
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Remote',
        value: 'remote_addr',
      }
    ],
  }),
  computed: {
    outposts() {
      return this.$store.state.o.outposts;
    }
  },
}
</script>
