<template>
  <v-container>
    <v-row>
      <v-col v-show="$vuetify.breakpoint.mdAndUp">
        <span></span>
      </v-col>
      <v-col>
        <v-form ref="form" v-model="valid" @submit.prevent="login()">
          <v-row>
            <v-col>
              <v-text-field
              v-model="username"
              label="User-name"
              :rules="usernameRules"
              required
            ></v-text-field>
          </v-col>
          </v-row>
          <v-row>
            <v-col>
             <v-text-field
              v-model="password"
              label="Password"
              :rules="passwordRules"
              required
              type="password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn :disabled="!valid" type="submit">Login</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col v-show="$vuetify.breakpoint.mdAndUp">
        <span></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getAdminToken } from '../services/AffiliateService';

  export default {
    name: 'Login',

    data: () => ({
      valid: false,
      username: '',
      password: '',
      usernameRules: [
        v => !!v || 'Required',
      ],
      passwordRules: [
        v => !!v || 'Required',
      ],
    }),

    methods: {
      login() {
        getAdminToken(this.username, this.password)
          .then(() => {
            this.$router.replace("/");
          })
          .catch(() => {
            this.username = '';
            this.password = '';  
          });
      }
    },
  }
</script>
