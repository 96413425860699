/**
 * @fileoverview Functions that gather information from the affiliate hub
 * server.
 * 
 */

let atoken = "";

/**
 * isAuthenticated tells you whether you have successfully logged in yet.
 * 
 * @returns {boolean} whether or not there is an atoken.
 */
export function isAuthenticated() {
    return Boolean(atoken);
}

/**
 * getAdminToken obtains an atoken from the affiliate hub server.
 * 
 * @param {string} username  the user-name
 * @param {string} password the password
 * @returns {Promise} resolving to the atoken string
 */
export async function getAdminToken(username, password) {
    const headers = {};
    headers['Authorization'] = 'Basic ' + btoa(username + ':' + password);
    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';
    const response = await fetch(httpURL('/a/login'), {
        method: 'POST',
        headers: headers
    });
    const token = await response.json();
    atoken = token;
    return atoken;
}

/**
 * getOutposts fetches the full list of live outposts from the affiliate hub server.
 * 
 * @returns {Promise} to get the list of outposts objects
 */
export async function getOutposts() {
    const headers = {};
    headers['Authorization'] = 'Bearer ' + atoken;
    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';
    const response = await fetch(httpURL('/a/connections'), {
        method: 'GET',
        headers: headers
    });

    if (400 <= response.status && response.status < 500) {
        atoken = ""
        throw new Error('Unauthorized')
    }

    const outposts = await response.json();
    return outposts;
}

/**
 * watchEventSocket obtains a websocket to that receives add/del events.
 * 
 * Jam in the Sec-WebSocket-Protocol, like:
 * 
 *   Sec-WebSocket-Protocol: AFFILIATE_ATOKEN, 1234567890abc
 * 
 * @returns {WebSocket} a websocket to that gets events.
 */
export function watchEventSocket() {
    let new_uri = wsURL();
    return new WebSocket(new_uri, ["AFFILIATE_ATOKEN", atoken]);
}

/**
 * httpURL constructs a URL for an affiliate hub resource.
 * 
 * @param {string} endpoint the resource endpoint
 * @returns {string} the URL of the affiliate hub resource
 */
function httpURL(endpoint) {
    let base_url = process.env.VUE_APP_BASE_URL || "/";
    let new_uri = pathJoin(base_url, endpoint);
    return new_uri;
}

/**
 * wsURL guesses the URL to the affiliate connections/ws.
 *
 * wsURL assumes the origin is the same and assumes it is just the end
 * endpoint that changes.
 * 
 * @return {string} the ws URL to connections/ws.
 */ 
function wsURL() {
    let base_url = "/";
    if (process.env.VUE_APP_BASE_URL) {
        base_url = process.env.VUE_APP_BASE_URL;
    } else {
        // Guess the websocket path.
        // You are required to specify the scheme so you cannot just say '/'.
        base_url = pathJoin(window.location.origin, window.location.pathname);
        // const pattern = new RegExp("/.*$")
        // base_url = base_url.replace(pattern, '');
    }

    base_url = base_url.replace(/^http/, 'ws');
    return pathJoin(base_url, "a/connections/ws");
}

/**
 * pathJoin joins paths.
 * 
 * I lifted the code from:
 * 
 * * https://gist.github.com/creationix/7435851
 * 
 * @param {string[]} parts the parts to join into a path
 * @param {string|undefined} sep the separator
 * @return {string} the joined path
 */ 
function pathJoin(...segments) {
    const parts = segments.reduce((parts, segment) => {
        // Remove leading slashes from non-first part.
        if (parts.length > 0) {
            segment = segment.replace(/^\//, '')
        }
        // Remove trailing slashes.
        segment = segment.replace(/\/$/, '')
        return parts.concat(segment.split('/'))
        }, []);

    const resultParts = []
    for (const part of parts) {
      if (part === '.') {
        continue
      }
      if (part === '..') {
        resultParts.pop()
        continue
      }
      resultParts.push(part);
    }

    return resultParts.join('/')
}
