import Vue from 'vue'
import VueRouter from 'vue-router'
import Shebang from '../shebang/Shebang.vue';
import Login from '../login/Login.vue';

import { isAuthenticated } from '../services/AffiliateService';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Shebang',
    component: Shebang,
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !isAuthenticated()) next({ name: 'Login' })
  else next()
})

export default router
