import { getOutposts } from '../../services/AffiliateService'

export default {
  namespaced: true,
  state: () => ({
    outposts: []
  }),
  mutations: {
    replace(state, outposts) {
      state.outposts = outposts
    }
  },
  actions: {
    refresh({commit}) {
      return getOutposts()
      .then(outposts => commit('replace', outposts))
    }
  },
  modules: {
  }
}
