import Vue from 'vue'
import Vuex from 'vuex'

import outpostModule from './modules/outposts'
import statusModule from './modules/status'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    o: outpostModule,
    s: statusModule,
  }
})
